// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AboutUs_about_header__32igH{\n    font-size: 2rem;\n    font-weight: 700;\n    margin-top: 3.7rem;\n}\n.AboutUs_btn_about_us__ArkCZ{\n    border-radius: 5px;\n    color: #fff;\n    font-weight: 700;\n    background-color: #000;\n    padding: .5rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/HomePage/AboutUs/AboutUs.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,sBAAsB;IACtB,cAAc;AAClB","sourcesContent":[".about_header{\n    font-size: 2rem;\n    font-weight: 700;\n    margin-top: 3.7rem;\n}\n.btn_about_us{\n    border-radius: 5px;\n    color: #fff;\n    font-weight: 700;\n    background-color: #000;\n    padding: .5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"about_header": "AboutUs_about_header__32igH",
	"btn_about_us": "AboutUs_btn_about_us__ArkCZ"
};
export default ___CSS_LOADER_EXPORT___;
