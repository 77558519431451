// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FooterHomePage_footerVisaMastercard__T93LN{\n\theight: 3rem;\n\tvertical-align: middle;\n}\n.FooterHomePage_footer__phF0p{\n\tfont-size: 0.8em;\n\tbackground-color: #000;;\n}\n.FooterHomePage_footer__phF0p a{\n\ttext-decoration: none;\n}\n\n@media screen and (min-width: 768px){\n\t.FooterHomePage_footer__phF0p{\n\t\tposition: relative;\n\t\twidth: 100vw;\n\t\t/*bottom: 3.5rem;*/\n\t\t/*margin-top: 3.5rem;*/\n\t}\n}\n", "",{"version":3,"sources":["webpack://src/components/Footers/FooterHomePage/FooterHomePage.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,sBAAsB;AACvB;AACA;CACC,gBAAgB;CAChB,sBAAsB;AACvB;AACA;CACC,qBAAqB;AACtB;;AAEA;CACC;EACC,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;CACvB;AACD","sourcesContent":[".footerVisaMastercard{\n\theight: 3rem;\n\tvertical-align: middle;\n}\n.footer{\n\tfont-size: 0.8em;\n\tbackground-color: #000;;\n}\n.footer a{\n\ttext-decoration: none;\n}\n\n@media screen and (min-width: 768px){\n\t.footer{\n\t\tposition: relative;\n\t\twidth: 100vw;\n\t\t/*bottom: 3.5rem;*/\n\t\t/*margin-top: 3.5rem;*/\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerVisaMastercard": "FooterHomePage_footerVisaMastercard__T93LN",
	"footer": "FooterHomePage_footer__phF0p"
};
export default ___CSS_LOADER_EXPORT___;
