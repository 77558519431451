// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WhyChooseUs_whyChooseUsH2__2tf-0{\n    font-size: 2rem;\n    font-weight: 800;\n    text-transform: uppercase;\n    letter-spacing: 2.5px;\n    padding: 2rem 6rem 0 2rem;\n}\n.WhyChooseUs_whyChooseUsWrapper__nWtu5{\n    background-repeat: no-repeat;\n    background-size: cover;\n    position: relative;\n}\n.WhyChooseUs_whyChooseUsLayer__xs8FA{\n    padding: 1rem 2rem;\n    background-image: linear-gradient(to bottom,rgba(255,255,255,1),rgba(255,255,255,0.8));\n}\n\n@media screen and (max-width: 768px) {\n    .WhyChooseUs_whyChooseUsH2__2tf-0{\n        padding: 0 !important;\n    }\n}\n", "",{"version":3,"sources":["webpack://src/components/HomePage/WhyChooseUs/WhyChooseUs.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,qBAAqB;IACrB,yBAAyB;AAC7B;AACA;IACI,4BAA4B;IAC5B,sBAAsB;IACtB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,sFAAsF;AAC1F;;AAEA;IACI;QACI,qBAAqB;IACzB;AACJ","sourcesContent":[".whyChooseUsH2{\n    font-size: 2rem;\n    font-weight: 800;\n    text-transform: uppercase;\n    letter-spacing: 2.5px;\n    padding: 2rem 6rem 0 2rem;\n}\n.whyChooseUsWrapper{\n    background-repeat: no-repeat;\n    background-size: cover;\n    position: relative;\n}\n.whyChooseUsLayer{\n    padding: 1rem 2rem;\n    background-image: linear-gradient(to bottom,rgba(255,255,255,1),rgba(255,255,255,0.8));\n}\n\n@media screen and (max-width: 768px) {\n    .whyChooseUsH2{\n        padding: 0 !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"whyChooseUsH2": "WhyChooseUs_whyChooseUsH2__2tf-0",
	"whyChooseUsWrapper": "WhyChooseUs_whyChooseUsWrapper__nWtu5",
	"whyChooseUsLayer": "WhyChooseUs_whyChooseUsLayer__xs8FA"
};
export default ___CSS_LOADER_EXPORT___;
