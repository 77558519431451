import s from './CustomerInfo.module.css';
import React from 'react';
import store from "../../../state/state";
import * as c from "../../../state/variables";

class CustomerInfo extends React.Component {

	componentDidMount() {
	}

	render(){
		// let id = 12;
		// fetch(c.API_ADDRESS + c.API_VERSION+ "/CountryList/getCountryNameById.php?id=" + id)
		// 	.then((response) => response.json())
		// 	.then((response) => {
		// 		store._state.userData.country = response.name
		// 		saveDataToLocalStorage()
		// 	})
		// let id1 = 14;
		// fetch(c.API_ADDRESS + c.API_VERSION+ "/CountryList/getCountryNameById.php?id=" + id1)
		// 	.then((response) => response.json())
		// 	.then((response) => {
		// 		store._state.userData.birthCountry = response.name
		// 		saveDataToLocalStorage()
		//
		// 	})
		// let info = JSON.parse(window.localStorage.getItem('state'))
		// const fName = info._state.registrationData.First_Name
		// const lName = info._state.registrationData.Last_Name
		// const country = info._state.userData.country
		// const bCountry = info._state.userData.birthCountry
		// const program = info._state.registrationData.What_visa_are_you_interested_in
		const fName = "Name"
		const lName = "Last Name"
		const country = "country"
		const bCountry = "Birth COuntry"
		const program = "Program"
			return(
				<div>
					<p>Dear <b>{fName} {lName}</b>,</p>
					<p>Congratulations!</p>
					<p>You are eligible to participate in the {program} program</p>
					<p>Your country of birth - {bCountry} </p> <p>Your country of residence -{country}</p> <p>Your case number: <b>622286</b></p>
					<p>To finalize your submission for the {program} &nbsp;program, please complete the payment</p>
				</div>
			)
		}
}

// const CustomerInfo = (props) => {
// 	let info = JSON.parse(window.localStorage.getItem('state'))
// 	const fName = info._state.registrationData.First_Name
// 	const lName = info._state.registrationData.Last_Name
// 	return(
// 		<div>
// 			<p>Dear <b>{fName} {lName}</b>,</p>
// 			<p>Congratulations!</p>
// 			<p>You are eligible to participate in the Work Visa program</p>
// 			<p>Your country of birth - Angola</p> <p>Your country of residence - Andorra</p> <p>Your case number: <b>622286</b></p>
// 			<p>To finalize your submission for the Work Visa &nbsp;program, please complete the payment</p>
// 		</div>
// 	)
// }
export default CustomerInfo;
