import * as c from "../../../state/variables";
import s from './AboutUs.module.css';

const AboutUs = (props) => {
	return(
		<div>
			<div className="about">
				<div className="padding_db_r sp_padding_none">
					<h2 className={s.about_header + " color_red sp_center about_header"}>ABOUT US</h2>
					<div className="sp_center">
						<p>At <b>{c.BRAND}</b>, our experts will handle all of your immigration needs through and
							through. With our unique approach, our team of RCIC’s will provide you with personalized
							assistance, consulting, and advising when it comes to all temporary and permanent resident
							applications. With each and every client, we always strive to represent your best interests
							in every case.</p>
						<p>When it comes to our clients, we always go above and beyond to deliver exceptional service.
							At the end of the day, we’re looking to make Canadian immigration more accessible, more
							affordable, and less strenuous. Each and every day, we work to not only meet the
							expectations of our clients, but to exceed them.</p>
						<p>Due to the nature of the beast, far too often clients seeking immigration into Canada rarely
							have any contact whatsoever with their Canadian immigration lawyers throughout the
							application process, either because the countries in which these clients reside permit
							contact, or because these lawyers may be located in a different city than the particular
							city the client is looking to move to. Coupled with the fact that correspondence is
							conducted via the mail service for immigration into Canada, this process has become
							extremely disconnected – especially between clients and the lawyers who are supposed to be
							representing them and their best interests.</p>
						<p>Here at <b>{c.BRAND}</b>, we’re looking to change that. Each and every day, we strive to
							offer a more personalized solution towards immigration.</p>
						<p className="padding_db_v">
							<a href="/registration" className={s.btn_about_us}>Apply Now</a>
						</p>
					</div>
				</div>
			</div>

		</div>
	)
}
export default AboutUs;
