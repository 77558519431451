const search = window.location.search;
const params = new URLSearchParams(search);

export const utm_source = params.has('utm_source')?params.get('utm_source') : 'utm_source';
export const utm_medium = params.has('utm_medium')?params.get('utm_medium') : 'utm_medium';
export const utm_campaign = params.has('utm_campaign')?params.get('utm_campaign') : 'utm_campaign';
export const utm_term = params.has('utm_term')?params.get('utm_term') : 'utm_term';
export const url = window.location.protocol + '//' + window.location.host + window.location.pathname;
export const url2 = window.location.href.split('?')[0];
export const refer = window.location.href;
