// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Step_number__38x9W{\n    height: 6rem;\n    font-size: 3rem;\n    line-height: 6rem;\n    text-align: center;\n    color: #7c7c7c;\n    font-weight: 700;\n}\n\n.Step_current_step__1ebJh {\n    color: #a8352e;\n}\n.Step_text__25Wot{\n    font-weight: 700;\n    color: #7c7c7c;\n    padding: 0;\n    margin-top: -1rem;\n}\n.Step_current_step__1ebJh .Step_text__25Wot{\n    color: black;\n}\n\n.Step_steps_delimetr__1IhgT{\n    border-top: 4px solid #7c7c7c;\n    margin-top: .5rem;\n}\n.Step_current_step__1ebJh .Step_steps_delimetr__1IhgT{\n    color: #a8352e;\n}\n", "",{"version":3,"sources":["webpack://src/components/Registration/Step/Step.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;AACA;IACI,gBAAgB;IAChB,cAAc;IACd,UAAU;IACV,iBAAiB;AACrB;AACA;IACI,YAAY;AAChB;;AAEA;IACI,6BAA6B;IAC7B,iBAAiB;AACrB;AACA;IACI,cAAc;AAClB","sourcesContent":[".number{\n    height: 6rem;\n    font-size: 3rem;\n    line-height: 6rem;\n    text-align: center;\n    color: #7c7c7c;\n    font-weight: 700;\n}\n\n.current_step {\n    color: #a8352e;\n}\n.text{\n    font-weight: 700;\n    color: #7c7c7c;\n    padding: 0;\n    margin-top: -1rem;\n}\n.current_step .text{\n    color: black;\n}\n\n.steps_delimetr{\n    border-top: 4px solid #7c7c7c;\n    margin-top: .5rem;\n}\n.current_step .steps_delimetr{\n    color: #a8352e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"number": "Step_number__38x9W",
	"current_step": "Step_current_step__1ebJh",
	"text": "Step_text__25Wot",
	"steps_delimetr": "Step_steps_delimetr__1IhgT"
};
export default ___CSS_LOADER_EXPORT___;
