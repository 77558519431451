import s from './ThankyouPage.module.css';



const ThankyouPage = () => {
	document.title = document.title + " thank you!"
	return(
		<div>
			<div className="wrapper">
				<div className={s.transparent_bg}>
					<div className={s.thankyou}>
						<div className={s.thankyouH1 + " center"}>Thank You!</div>
						<div className={s.divider + ' w_10 sp_w_30 color_white margin_a'}></div>
						<h2 className={s.h2 + " center"}>Thanks for your submission.
							<br className="hide sp_show"/>
								We will contact in the next 24H with the results.
						</h2>
					</div>
				</div>
			</div>
		</div>
	)
}
export default ThankyouPage;
