import './ExpertiseElement.css';

const ExpertiseElement = (props) => {
	let imgsource = "/img/Expertise/" + props.prop.img;
	return(
		<div className="inline relative w_20 sp_w_95 sp_center top padding point">
			<div className="wrapper">
				<p className="number color_red">{props.prop.id}</p>
				<img className="show w_100" src={imgsource} alt={props.prop.title}/>
			</div>
			<div className="padding_t title center color_red">{props.prop.title}</div>
			<p className="center">{props.prop.text}</p>
		</div>
	)
}
export default ExpertiseElement;
