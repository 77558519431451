import s from './PaymentFrame.module.css';

const PaymentFrame = (props) => {
	return(
		<div>
			PaymentFrame
		</div>
	)
}
export default PaymentFrame;
