import * as c from "../../../state/variables";
import s from './WhyChooseUs.module.css'
import video from './src/canada.mp4'
import bg from './src/why_us_bg.jpg'
import about_testimonials from "../../../images/about_testimoinals_bg.jpg";
import ReactPlayer from 'react-player'

const WhyChooseUs = (props) => {
	return(
		<div>
			<div className={s.whyChooseUsWrapper} style={{backgroundImage: 'url("'+bg+'")'}} id="whyus">
				<div className={s.whyChooseUsLayer}>
					<div className="max_width_custom">
						<div className="title center color_red padding_db_b"><h2 className={s.whyChooseUsH2}>WHY CHOOSE US</h2></div>
						<div className="w_50 sp_w_100 inline whyus_pictures">
							<ReactPlayer  url={video} controls={true} autoPlay={true} width='90%' volume={1}/>
						</div>
						<div className="w_50 sp_w_100 top inline whyus_whychoose center">
							<div className="padding_db_h sp_padding_none sp_padding_db_t whyus_whychoose_wrapper">
								<div className="padding_db whyus_whychoose_text bg_white" id="why">
									<div>
										<p>Here at {c.BRAND}, we believe two aspects of our business set us apart
											from the rest. Our high level of professionalism and our exemplary service
											allow us to offer our clients more than ever before, in a more personalized,
											humanized manner. After all, immigration is all about a person-to-person
											process, so in our minds, the legal process should be too.</p>
										<p>Our service directly ensures that our clients are always aware of, and up to
											date with all of today’s immigration requirements and procedures. Each and
											every day, we work to further optimize our services, so that we can
											streamline our process and get our clients into Canada faster than ever
											before.</p>
										<p>If you’d like a free consultation, contact us today! Our attention to detail
											and our thorough approach to helping you understand your options will give
											you a brand new perspective on the immigration process. We want to see you
											succeed in whatever way possible, and we’ll always work with you to
											determine your very best options before you make any payments!.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="googlemap w_100" id="contact">
				<div className="w_100 bg_black">
					<iframe frameBorder="0" width="100%" height="300px" scrolling="no" marginHeight="0" marginWidth="0"
							src="https://maps.google.com/maps?q=%23700-838%20W%20Hastings%20Street%20%20Vancouver%2C%20British%20Columbia%20V6C0A6%20%20Canada.&amp;t=m&amp;z=8&amp;output=embed&amp;iwloc=near"
							aria-label="#700-838 W Hastings Street  Vancouver, British Columbia V6C0A6  Canada."></iframe>
				</div>
			</div>
		</div>
	)
}
export default WhyChooseUs;
