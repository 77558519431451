import s from './Registration.module.css';
import Step from "./Step/Step";
import StepsHeader from "../DifferenceComponents/StepsHeader/StepsHeader";
import RegistrationForm from './RegistrationForm/MonthlyIncomeForm/MonthlyIncomeForm';
import * as c from "../../state/variables";

document.title = document.title + " registration"

const Registration = (props) => {
	return(
		<div>
			<StepsHeader currentStep={1}/>
			<RegistrationForm state={props.state}/>
		</div>
	)
}
export default Registration;
