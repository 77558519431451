
import {useState, useEffect} from 'react';

export function MyComponent() {

    let allCountries='';
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    useEffect(() => {
        fetch("https://www.canada-direct.com/php/api/1.0/CountryList/read.php")
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result.records);
                },
            (error) => {
                setIsLoaded(true);
                setError(error);
                }
            )
        }, [])

        if (error) {
            return <div>Ошибка: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Загрузка...</div>;
        } else {
            allCountries = items;
            return (
                items.map((item)=>(
                    <option value={item.id}>{item.name}</option>
                ))
            );
        }
    }
