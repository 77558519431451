import s from './PrivacyPolicy.module.css';
import * as c from "../../state/variables";

const PrivacyPolicy = (props) => {
	// const domain = props.state.maindata.domain
	// const support_email = props.state.maindata.support_email
	// const brand = props.state.maindata.brand
	console.log(c.SUPPORT_EMAIL)
	return(
		<div>
			<div className="padding_qt menu">
				<div className="inline sp_w_100 w_20 middle">
					<a href="/"><img className={s.logo} src={c.API_ADDRESS+c.API_VERSION+'/domains/'+c.BRAND+'/icons/logo.png'} alt={c.BRAND + ' logo'}/></a>
				</div>
			</div>

			<div className="w_90 sp_w_90 padding_db">
				<div class="w_33 sp_w_90 inline color_red top"><h2 className={s.privacyPolicyH2}>PRIVACY POLICY</h2></div>
				<div className={s.text}>
					<p className="padding_db_b">This privacy policy has been compiled to better serve those who are concerned with how their ‘Personally identifiable information’ (PII) is being used online. PII, as used in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.</p>

					<p className="text_paragraph text_bold">What personal information do we collect from the people that visit our blog, website or app?</p>
					<p className="text_paragraph">When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number, Occupation, Birth country, Age or other details to help you with your experience.</p>

					<p className="text_paragraph text_bold">When do we collect information?</p>
					<p className="text_paragraph">We collect information from you when you register on our site, fill out a form or enter information on our site. Your information we are stored in our systems for 2 years from your first registration.</p>
					<p className="text_paragraph">We do not collect credit card information. The payment is made only from the client-side.</p>
					<p className="text_paragraph text_bold">How do we use your information?</p>
					<p className="text_paragraph">We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:</p>
					<ul>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text"> To personalize user’s experience and to allow us to deliver the type of content and product offerings in which you are most interested.</span>
						</li>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text"> To improve our website in order to better serve you.</span>
						</li>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text"> To allow us to better service you in responding to your customer service requests.</span>
						</li>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text"> To administer a contest, promotion, survey or other site feature.</span>
						</li>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text"> To quickly process your transactions.</span>
						</li>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text"> To ask for ratings and reviews of services or products</span>
						</li>
					</ul>
					<p className="text_paragraph text_bold">How do we protect visitor information?</p>
					<p className="text_paragraph">Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible.</p>
					<p className="text_paragraph">We use regular Malware Scanning.</p>
					<p className="text_paragraph">Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.</p>
					<p className="text_paragraph">We implement a variety of security measures when a user places an order enters, submits, or accesses their information to maintain the safety of your personal information.</p>
					<p className="text_paragraph">All transactions are processed through a gateway provider and are not stored or processed on our servers.</p>

					<p className="text_paragraph text_bold">Do we use ‘cookies’?</p>

					<p className="text_paragraph">We do not use cookies for tracking purposes</p>
					<ul>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text">Understand and save user’s preferences for future visits.</span>
						</li>
					</ul>
					<p className="text_paragraph">You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer) settings. Each browser is a little different, so look at your browser’s Help menu to learn the correct way to modify your cookies.</p>

					<p className="text_paragraph text_bold">If users disable cookies in their browser:</p>

					<p className="text_paragraph">If you disable cookies off, some features will be disabled It will turn off some of the features that make your site experience more efficient and some of our services will not function properly.</p>
					<p className="text_paragraph">However, you can still place orders.</p>

					<p className="text_paragraph text_bold">Third-party disclosure</p>

					<p className="text_paragraph text_bold">Do we disclose the information we collect to Third-Parties?</p>

					<p className="text_paragraph">We will transfer to outside parties your personal details,  by using rofessional experts as a third party.</p>

					<p className="text_paragraph text_bold">Third-party links</p>

					<p className="text_paragraph">Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</p>
					<p className="text_paragraph text_bold">Google</p>

					<p className="text_paragraph">Google’s advertising requirements can be summed up by Google’s Advertising Principles. They are put in place to provide a positive experience for users. <a href="https://support.google.com/adwordspolicy/answer/1316548?hl=en">Read More</a></p>
					<p className="text_paragraph">We have not enabled Google AdSense on our site but we may do so in the future.</p>

					<p className="text_paragraph text_bold">California Online Privacy Protection Act</p>
					<p className="text_paragraph">CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law’s reach stretches well beyond California to require a person or company in the United States (and conceivably the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy. – See more at: http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf</p>

					<p className="text_paragraph text_bold">According to CalOPPA we agree to the following:</p>
					<p className="text_paragraph">Users can visit our site anonymously.</p>
					<p className="text_paragraph">Once this privacy policy is created, we will add a link to it on our home page or as a minimum on the first significant page after entering our website.</p>
					<p className="text_paragraph">Our Privacy Policy link includes the word ‘Privacy’ and can be easily be found on the page specified above.</p>
					<p className="text_paragraph">Users will be notified of any privacy policy changes:</p>

					<ul>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text">On our Privacy Policy Page</span>
						</li>
					</ul>

					<p className="text_paragraph">Users are able to change their personal information:</p>
					<ul>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text">By emailing us</span>
						</li>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text">By calling us</span>
						</li>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text">By logging in to their account</span>
						</li>
					</ul>
					<p className="text_paragraph text_bold">How does our site handle do not track signals?</p>
					<p className="text_paragraph">We honor do not track signals and do not track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.</p>

					<p className="text_paragraph text_bold">Does our site allow third-party behavioral tracking?</p>
					<p className="text_paragraph">It’s also important to note that we do not allow third-party behavioral tracking</p>
					<p className="text_paragraph text_bold">COPPA (Children Online Privacy Protection Act)</p>
					<p className="text_paragraph">When it comes to the collection of personal information from children under 13, the Children’s Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation’s consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children’s privacy and safety online.</p>
					<p className="text_paragraph">We do not specifically market to children under 13.</p>

					<p className="text_paragraph text_bold">Fair Information Practices</p>
					<p className="text_paragraph">The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.</p>

					<p className="text_paragraph text_bold">In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:</p>

					<p className="text_paragraph">We will notify the users via email</p>
					<ul>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text">Within 1 business day</span>
						</li>
					</ul>
					<p className="text_paragraph">We will notify the users via in-site notification</p>
					<ul>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text">Within 1 business day</span>
						</li>
					</ul>

					<p className="text_paragraph">We also agree to the Individual Redress Principle, which requires that individuals have a right to pursue legally enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.</p>
					<p className="text_paragraph text_bold">CAN SPAM Act</p>
					<p className="text_paragraph">The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.</p>

					<p className="text_paragraph text_bold">We collect your email address in order to:</p>
					<ul>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text">Send information, respond to inquiries, and/or other requests or questions.</span>
						</li>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text">Process orders and to send information and updates pertaining to orders.</span>
						</li>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text">We may also send you additional information related to your product and/or service.</span>
						</li>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text">Market to our mailing list or continue to send emails to our clients after the original transaction has occurred.</span>
						</li>
					</ul>
					<p className="text_paragraph text_bold">To be in accordance with CANSPAM we agree to the following:</p>
					<ul>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text">NOT use false or misleading subjects or email addresses.</span>
						</li>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text">Identify the message as an advertisement in some reasonable way.</span>
						</li>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text">Include the physical address of our business or site headquarters.</span>
						</li>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text">Monitor third-party email marketing services for compliance, if one is used.</span>
						</li>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text">Honor opt-out/unsubscribe requests quickly.</span>
						</li>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text">Allow users to unsubscribe by using the link at the bottom of each email.</span>
						</li>
					</ul>

					<p className="text_paragraph text_bold">If at any time you would like to unsubscribe from receiving future emails, you can email us at</p>
					<ul>
						<li>
							<span className="text"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="text">Follow the instructions at the bottom of each email.</span>
						</li>
					</ul>
					<p className="text_paragraph">and we will promptly remove you from <b>ALL</b> correspondence.</p>

					<p className="text_paragraph text_bold">Contacting Us</p>
					<p className="text_paragraph">If there are any questions regarding this privacy policy you may contact us using the information below.</p>
					<p className="text_paragraph">{c.BRAND}</p>
					<p>CAIC IMMIGRATION SL</p>
					<p><b>Canada:</b> #700-838 W Hastings Street, Vancouver, British Columbia V6C0A6</p>
					<p><b>Israel</b> (Only Tech Department)</p>
					<p>Ariel Sharon 4, Ramat Gan, Israel.</p>
					<p><b>Spain</b> Calle Balmes 173, 08006, Barcelona, Spain.</p>
					<p className="text_paragraph">{c.SUPPORT_EMAIL}</p>
					<br/>
					<br/>
				</div>
			</div>
			<p className="text_paragraph"></p>
			<p className="text_paragraph text_bold"></p>
		</div>
	)
}
export default PrivacyPolicy;
