import './Expertise.module.css';
import * as c from "../../../state/variables";
import ExpertiseElement from "./ExpertiseElement/ExpertiseElement";
let store = {
	expertise:{
		[0]:{'id':'1','title':'Business Visa','text':'At ' + c.BRAND + ', we specialize our services around an extensive range of legal services for Inter-company Transfers, NAFTA Transferees, and Business Visitor Visas, allowing us to help secure these documents for workers looking to make their way into Canada.','img':'0001.png'},
		[1]:{'id':'2','title':'Permanent Residency','text':'If you’re seeking permanent residency, our team of professional immigration consultants will work with you to represent you in Family Class Sponsorships, Express Entry, Human and Compassionate Permanent Residency, and much more. We’ll always work with you to explore every possible option.','img':'0002.png'},
		[2]:{'id':'3','title':'Temporary Residency','text':'For all study permits, work permits, visitor visas, and entry into the Caregiver Program, '+c.BRAND+' can help. Our team of can offer you exceptional assistance and advising as you continue to complete your application.','img':'0003.png'},
		[3]:{'id':'4','title':'Citizenship Services','text':'Becoming a Canadian citizen can be a time consuming process, for that very reason, our dedicated team of immigration consultants are prepared to fully and completely represent you and your interests as they guide you throughout the application, revocation, or appeals processes. In addition, our team will help to provide more clarity and insight into the status of your application, as well as all relevant law changes that could potentially impact or delay your application.','img':'0004.png'}
	}
}
const Expertise = (props) => {
	return(
		<div className="expertise sp_padding_db_t w_100" id="expertise">
			<div className="title center margin_db_b padding_db color_red sp_padding_none sp_padding_b"><h2>OUR
				EXPERTISE</h2>
			</div>
			<div className="relative w_100 max_width_custom w_100 center">
				<ExpertiseElement prop={store.expertise["0"]}/>
				<ExpertiseElement prop={store.expertise["1"]}/>
				<ExpertiseElement prop={store.expertise["2"]}/>
				<ExpertiseElement prop={store.expertise["3"]}/>
			</div>
		</div>
	)
}
export default Expertise;
