// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TermsOfServices_h2__3TFv2{\n    font-size: 1.8rem;\n    font-weight: 800;\n    text-transform: uppercase;\n    letter-spacing: 2.5px;\n    padding: 0;\n}\n.TermsOfServices_text__3dkz6{\n    /*w_60 sp_w_90 inline*/\n    width: 60%;\n    display: inline-block;\n    padding-top: 2rem;\n    padding-left: 2rem;\n    color: #000;\n    font-size: 1.1rem;\n    font-weight: 400;\n    line-height: 1.5em;\n    letter-spacing: 1px;\n}\n.TermsOfServices_logo__QlMqJ{\n    width: 5rem;\n    padding: 1rem;\n}\n@media screen and (max-width: 768px) {\n    .TermsOfServices_h2__3TFv2{\n        padding: 1rem 0;\n    }\n    .TermsOfServices_text__3dkz6{\n        width: 90%;\n        padding-top: 2rem;\n    }\n\n}\n", "",{"version":3,"sources":["webpack://src/components/TermsOfServices/TermsOfServices.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,yBAAyB;IACzB,qBAAqB;IACrB,UAAU;AACd;AACA;IACI,sBAAsB;IACtB,UAAU;IACV,qBAAqB;IACrB,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,aAAa;AACjB;AACA;IACI;QACI,eAAe;IACnB;IACA;QACI,UAAU;QACV,iBAAiB;IACrB;;AAEJ","sourcesContent":[".h2{\n    font-size: 1.8rem;\n    font-weight: 800;\n    text-transform: uppercase;\n    letter-spacing: 2.5px;\n    padding: 0;\n}\n.text{\n    /*w_60 sp_w_90 inline*/\n    width: 60%;\n    display: inline-block;\n    padding-top: 2rem;\n    padding-left: 2rem;\n    color: #000;\n    font-size: 1.1rem;\n    font-weight: 400;\n    line-height: 1.5em;\n    letter-spacing: 1px;\n}\n.logo{\n    width: 5rem;\n    padding: 1rem;\n}\n@media screen and (max-width: 768px) {\n    .h2{\n        padding: 1rem 0;\n    }\n    .text{\n        width: 90%;\n        padding-top: 2rem;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"h2": "TermsOfServices_h2__3TFv2",
	"text": "TermsOfServices_text__3dkz6",
	"logo": "TermsOfServices_logo__QlMqJ"
};
export default ___CSS_LOADER_EXPORT___;
