import s from './Payment.module.css';
import StepsHeader from "../DifferenceComponents/StepsHeader/StepsHeader";
import CustomerInfo from "./CustomerInfo/CustomerInfo";
import OfferCounter from "./OfferCounter/OfferCounter";
import OfferServices from "./OfferServices/OfferServices";
import PaymentFrame from "./PaymentFrame/PaymentFrame";

const Payment = (props) => {

	return(
		<div>
			Registration
			<a href="/">HomePage</a><br/>
			<a href="/registration">registration</a>
			<StepsHeader currentStep={2}/>
			<div className="w_100 max_width_1024 padding">
				<div className="w_50 inline">
					<div className="100">
						<CustomerInfo/>
						<OfferCounter/>
						<OfferServices/>
					</div>
				</div>
				<div className="w_50 inline">
					<div className="w_100">
						<PaymentFrame/>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Payment;
