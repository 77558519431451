// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Expertise_wrapper__2J9aa {\n    border-radius: .5rem;\n    overflow: hidden;\n}\n.Expertise_number__-N0yr {\n    position: absolute;\n    font-size: 6rem;\n    top: -9rem;\n    font-weight: 900;\n}\n", "",{"version":3,"sources":["webpack://src/components/HomePage/Expertise/Expertise.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,eAAe;IACf,UAAU;IACV,gBAAgB;AACpB","sourcesContent":[".wrapper {\n    border-radius: .5rem;\n    overflow: hidden;\n}\n.number {\n    position: absolute;\n    font-size: 6rem;\n    top: -9rem;\n    font-weight: 900;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Expertise_wrapper__2J9aa",
	"number": "Expertise_number__-N0yr"
};
export default ___CSS_LOADER_EXPORT___;
