import {TESTIMONIALS} from '../../../state/testimonials';
import carousel1 from './images/0001.png';
import carousel2 from './images/0002.png';
import carousel3 from './images/0003.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import s from './Testimonials.module.css';
import quote from './images/quot.png'
import trust1 from './images/trust_1.png';
import trust2 from './images/trust_2.png';
const Testimonials = (props) => {

	return(
		<div>

			<div className={s.testimonials}>
				<div className="padding_h padding_db_b">
					<img className={s.quote + " sp_hide"} src={quote}/>
						<h2 className={s.testimonialsH2}>TESTIMONIALS</h2>
						<div className="testiomonial w_100">
							<h3 className={s.testimonialsH3}>SEE WHAT OUR PEOPLE ARE SAYING</h3>
						</div>
					<div>
						<Carousel
							style={{backgroundColor:'transparent'}}
							showArrows={false}
							showStatus={false}
							showIndicators={false}
							showThumbs={false}
							infiniteLoop={true}
							autoPlay={true}
						>
							<div className={s.testimonials_img_wrapper}>
								<p>{TESTIMONIALS[0].text}</p>
								<img src={carousel1} className={s.testimonials_img_wrapper} alt={'alt'} />
								<p>{TESTIMONIALS[0].name}</p>
								<p>{TESTIMONIALS[0].country}</p>

							</div>
							<div className={s.testimonials_img_wrapper}>
								<p>{TESTIMONIALS[1].text}</p>
								<img src={carousel2} className={s.testimonials_img_wrapper} alt={'alt'} />
								<p>{TESTIMONIALS[1].name}</p>
								<p>{TESTIMONIALS[1].country}</p>
							</div>
							<div className={s.testimonials_img_wrapper}>
								<p>{TESTIMONIALS[2].text}</p>
								<img src={carousel3} className={s.testimonials_img_wrapper} alt={'alt'} />
								<p>{TESTIMONIALS[2].name}</p>
								<p>{TESTIMONIALS[2].country}</p>
							</div>
						</Carousel>
					</div>
						<div className="w_100 trusts hide">
							<div><img className={s.trust_img} src={trust1}/></div>
							<div><img className={s.trust_img} src={trust2}/></div>
						</div>
				</div>
			</div>

		</div>
	)
}
export default Testimonials;
