import * as c from "../state/variables";

//old
// export const TESTIMONIALS = [
//     {id: 1,name:'Marie Cooper',country:'United Kingdom',img:'0001.png', text: "Visa granted today!! And I want to say a very big thank you to "+ c.BRAND + ", specially for Mrs Ethel Kohn! for their support and expert advice. It has been a stress free experience! I’m impressed with their responsible and thorough work. They just made a complex process seem easy."},
//     {id: 2,name:'Ohad Cohen',country:'Italy',img:'0002.png', text: 'I couldn’t have asked for a better Visa agent. The staff at ' + c.BRAND + ' was amazing and very efficient all through the visa process. Even though I’m in another country, you have made the entire process smooth and easy. Jake Tyler You\'re The Best!'},
//     {id: 3,name:'Doreen Zagorsky',country:'Israel',img:'0003.png', text: 'I just want to express how thrilled I am with your service, and all thanks to your highly reliable customer service agents (Rachel Gary). I would not hesitate to recommend ' + c.BRAND + ' to any one who is interested in immigrating to Canada in the near future.'},
// ]


export const TESTIMONIALS = [
    {
      id: 1,
      name: "Murlita Percival",
      country: "Saint Kitts and Nevis",
      img: "0001.png",
      text:"Good day "+
      "I am writing to express my satisfaction with the service provided to my son, Jahvon Percival and I in obtaining his student Visa. I personally want to say thank you to Josh and Stephanie who went the extra mile to make this come to fruition."+
      "My son is now enrolled and actively participating in school at Fanshawe International College since Tuesday of this week. He is overjoyed with this opportunity to further his education abroad." +
      "I also enjoyed my short stay with him in Canada even though this is my third visit but first to Ontario. I am leaving early in the morning for New York before returning home to the Caribbean. He is comfortably settled on campus."+
      "Thank you again for making this happen for my son. We both appreciate it very much. God bless."+
      "Kind regards"
      
        
    },
    {
      id: 2,
      name: "Oleksandra Drachenko",
      country: "Ukraine ",
      img: "0002.png",
      text:
      "I want to take this opportunity to thank you, Rachel Gray and Adam Strauss, for their work on my case."
      + "Both are absolutely incredible and provided me with great support." +
      "The communication is clear. And I always receive answers to even the silliest questions."+
      "I am looking forward to moving to the next stage."
    },
    {
      id: 3,
      name: "Paula Mariel Rey Melcon",
      country: " Argentina",
      img: "0003.png",
      text:
        "I am still in the process of applying for a visa. Through all this, CFIS has been extremely professional and very patient when it comes to explaining things. The support staff is always kind and helpful. Their work was great! Thank you Canada Direct for your excellent service.",
    },
  ];