import s from './OfferCounter.module.css';

const OfferCounter = (props) => {
	return(
		<div>
			OfferCounter
		</div>
	)
}
export default OfferCounter;
