import * as c from "./variables";

export * from './variables'
export async function CountryCode(id) {
    console.log(c.API_ADDRESS + " " + c.API_VERSION)
    const response = await fetch(c.API_ADDRESS + c.API_VERSION+"/CountryList/ContryPrefixCodeById.php?id="+id);
    console.log()
    const data = await response.json();
    // document.getElementById('span_prefix').innerHTML = data[0].code;
    document.getElementById('prefix').value = "+"+data[0].code;

    let w = document.getElementById('Email');
}

// const width = document.getElementById('Email').offsetWidth;
// export const style = {width: `${width}px`}
