export default function UserScore(data){
    let res = 0;
    if(data['Education_Level'] !== '') res += scoringEducatonLevel(data['Education_Level'])
    // if(isset($arr['Education_Level'])) $res += scoringEducatonLevel($arr['Education_Level']);
    // if(isset($arr['Speaking_English_Level'])) $res += scoringEnglishLevel($arr['Speaking_English_Level']);
    // if(isset($arr['When_would_you_like_to_immigrate'])) $res += scoringImmigrateTime($arr['When_would_you_like_to_immigrate']);
    // if(isset($arr['Monthly_income_USD'])) $res += scoringIncome($arr['Monthly_income_USD']);
    // if(isset($arr['Savings'])) $res += scorintSavings($arr['Savings']);
    // if(isset($arr['Age'])) $res += scoringAge($arr['Age']);
    // if(isset($arr['Been_in_Canada'])) $res += scoringBeenInCanada($arr['Been_in_Canada']);
    // if(isset($arr['Marital_Status'])) $res += scoringMaritalStatus($arr['Marital_Status']);
    // if(isset($arr['Business_Owner'])) $res += scoringBusinessOwner($arr['Business_Owner']);
    return res;
}

function scoringEducatonLevel(eduLevel){
    if(eduLevel === 'Primary school only') return 0;
    if(eduLevel === 'High School Degree') return 1;
    if(eduLevel === 'Vocational School') return 0;
    if(eduLevel === 'University Degree') return 2;
    if(eduLevel === 'Master Degree') return 3;
    if(eduLevel === 'Doctorate Degree') return 2;
    if(eduLevel === 'Other') return 0;
    return 0;
}
