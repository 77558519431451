import s from './Step.module.css';


const Step = (props) => {
	let step = props.step;
	let active = props.active ? s.current_step : '';
	let current_step = props.active ? s.current_step : '';
	return(
		<div className={current_step + ' w_33 sp_w_33 center top inline step'}>
			<div className={active + ' w_40 margin_a circle'}>
				<div className={s.number + ' ' + active}>{step}<p className={s.steps_delimetr}></p></div>
				<i className="arrow right"></i>
			</div>
			<div className={s.text + ' color_black padding_hf_t'}>Eligibility</div>
		</div>
	)
}
export default Step;
