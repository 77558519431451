// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Registration_number__3t6ED{\n    height: 6rem;\n    font-size: 3rem;\n    line-height: 6rem;\n    text-align: center;\n    color: #7c7c7c;\n    font-weight: 700;\n}\n\n.Registration_current_step__2lSOi {\n    color: #a8352e;\n}\n\n.Registration_info__nXAxt .Registration_fill_text__B85Bc{\n    font-size: 1.4em;\n    font-weight: 600;\n}\n.Registration_info__nXAxt .Registration_fill_text_good_luck__2g5VF{\n    font-size: 2.5rem;\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n}\n\n.Registration_alert_border__1CKX4{\n    border: 1px solid red !important;\n}\n", "",{"version":3,"sources":["webpack://src/components/Registration/Registration.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".number{\n    height: 6rem;\n    font-size: 3rem;\n    line-height: 6rem;\n    text-align: center;\n    color: #7c7c7c;\n    font-weight: 700;\n}\n\n.current_step {\n    color: #a8352e;\n}\n\n.info .fill_text{\n    font-size: 1.4em;\n    font-weight: 600;\n}\n.info .fill_text_good_luck{\n    font-size: 2.5rem;\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n}\n\n.alert_border{\n    border: 1px solid red !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"number": "Registration_number__3t6ED",
	"current_step": "Registration_current_step__2lSOi",
	"info": "Registration_info__nXAxt",
	"fill_text": "Registration_fill_text__B85Bc",
	"fill_text_good_luck": "Registration_fill_text_good_luck__2g5VF",
	"alert_border": "Registration_alert_border__1CKX4"
};
export default ___CSS_LOADER_EXPORT___;
