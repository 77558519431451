import s from './OfferServices.module.css';

const OfferServices = (props) => {
	return(
		<div>
			OfferServices

		</div>
	)
}
export default OfferServices;
