// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PrivacyPolicy_privacyPolicyH2__NEaxk{\n    font-size: 2rem;\n    font-weight: 800;\n    text-transform: uppercase;\n    letter-spacing: 2.5px;\n    padding: 10rem 6rem 0 2rem;\n}\n.PrivacyPolicy_text__1dnIi{\n    width: 66%;\n    display: inline-block;\n    padding-top: 8rem;\n    color: #000000;\n    font-size: 1.1rem;\n    font-weight: 400;\n    line-height: 1.5em;\n    letter-spacing: 1px;\n}\n.PrivacyPolicy_logo__4pbNf{\n    width: 5rem;\n    padding: 1rem;\n}\n@media screen and (max-width: 768px) {\n    h2{\n        padding: 1rem 0;\n    }\n    .PrivacyPolicy_text__1dnIi{\n        width: 90%;\n        padding-top: 2rem;\n    }\n\n}\n", "",{"version":3,"sources":["webpack://src/components/PrivacyPolicy/PrivacyPolicy.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,yBAAyB;IACzB,qBAAqB;IACrB,0BAA0B;AAC9B;AACA;IACI,UAAU;IACV,qBAAqB;IACrB,iBAAiB;IACjB,cAAc;IACd,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,aAAa;AACjB;AACA;IACI;QACI,eAAe;IACnB;IACA;QACI,UAAU;QACV,iBAAiB;IACrB;;AAEJ","sourcesContent":[".privacyPolicyH2{\n    font-size: 2rem;\n    font-weight: 800;\n    text-transform: uppercase;\n    letter-spacing: 2.5px;\n    padding: 10rem 6rem 0 2rem;\n}\n.text{\n    width: 66%;\n    display: inline-block;\n    padding-top: 8rem;\n    color: #000000;\n    font-size: 1.1rem;\n    font-weight: 400;\n    line-height: 1.5em;\n    letter-spacing: 1px;\n}\n.logo{\n    width: 5rem;\n    padding: 1rem;\n}\n@media screen and (max-width: 768px) {\n    h2{\n        padding: 1rem 0;\n    }\n    .text{\n        width: 90%;\n        padding-top: 2rem;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"privacyPolicyH2": "PrivacyPolicy_privacyPolicyH2__NEaxk",
	"text": "PrivacyPolicy_text__1dnIi",
	"logo": "PrivacyPolicy_logo__4pbNf"
};
export default ___CSS_LOADER_EXPORT___;
